.fontRed {
  color: #CF0032;

}

.input {
  font-size: 1rem;
  margin: 5px 0;
  padding: 10px;
  width: 100%;
}

.downloadBtn {
  background-color: var(--ap-green);
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
  padding: 10px;
  transition: 0.2s ease;
}

.downloadBtn:hover {
  background-color: #00484d;
}

.background {
  background: linear-gradient(45deg, #c0bfbf 0%, #ffffff 80%);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  /* scale: 1.2 */
}

.backgroundRed {
  background-color: #CF0032;
}

.fontGreen {
  color: #024547;
}

.backgroundGreen {
  background-color: #024547;
}

.fontWhite {
  color: #ffffff;
}

.backgroundWhite {
  background-color: #ffffff;
}

.fontBlack {
  color: #000000;
}

.backgroundBlack {
  background-color: #000000;
}

.topNav {
  padding: 20px
}

.logo {
  margin-left: 60px;
}

.dots_one {
  transform: translateY(770px);
  width: 212px;
  height: 80px;
}

.dots_two {
  transform: translateY(220px) translateX(1048px);
  /* z-index: -1; */
  position: absolute;
}

.box_one {
  position: absolute;
  right: -30rem;
  transform: translateY(-550px);
  /* z-index: -1; */
}

.box_two {
  position: absolute;
  right: 40rem;
  transform: translateY(420px);
  /* z-index: -1; */
  width: 88px;
  height: 88px;
}

.box_three {
  position: absolute;
  right: 35rem;
  transform: translateY(620px);
  width: 57px;
  height: 57px;
  /* z-index: -1; */
}

.box_four {
  position: absolute;
  right: 15rem;
  transform: translateY(620px);
  /* z-index: -1; */
  width: 37px;
  height: 37px;
}

.box_five {
  position: absolute;
  left: -30rem;
  transform: translateY(540px);
  /* z-index: -1; */
  display: none;
}

.box_six {
  position: absolute;
  left: 20rem;
  transform: translateY(640px);
  z-index: -1;
}

.box_seven {
  position: absolute;
  left: 90rem;
  transform: translateY(690px);
  z-index: -1;
}

.header {
  font-size: 32px;
  /* text-align: center; */
}

.label {
  font-size: 24px;
  color: #5E5E5E;
}

.oldTitle {
  color: rgb(97, 97, 97);
  font-size: 1.6rem;
  font-weight: bold;
  margin: 40px 20px;
}

.title {
  font-size: 32px;
  margin-left: 1rem;
}

.line {
  display: inline-block;
  margin-left: 0.1rem;
  width: 5px;
  font-size: 24px;
}

.cursor {
  display: inline-block;
  background-color: #5E5E5E;
  margin-left: 0.1rem;
  width: 3px;
  animation: blink 1s infinite;
  font-size: 24px;
}

.cursor.typing {
  animation: none;
}

@keyframes blink {
  0% {
    background-color: #5E5E5E;
  }

  49% {
    background-color: #5E5E5E;
  }

  50% {
    background-color: transparent;
  }

  99% {
    background-color: transparent;
  }

  100% {
    background-color: #5E5E5E;
  }
}



.section {
  width: 100%
}

.clients {
  width: auto;
  height: 100vh;
}

.content {
  padding: 90px 90px 40px 90px;
  max-width: 1640px;
  margin: 0 auto;
}

.heroContent {
  display: flex;
}

.productsContent {
  background-color: #CF0032;
}

.footerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footerColumnOne {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between
}

.footerColumnFour {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .logo {
    margin-left: 0px;
  }

  .box_one {
    right: -60rem;
    transform: translateY(-650px);
    width: 1216px;
    height: 1216px;
  }

  .box_two {
    right: 5rem;
    transform: translateY(480px);
  }

  .box_three {
    right: 20rem;
    transform: translateY(520px);
  }

  .box_four {
    right: 10rem;
    transform: translateY(670px);
  }

  .title {
    font-size: 28px;
  }

  .content {
    padding: 30px;
  }

  .footerContent {
    flex-direction: column;
    justify-content: center;
  }

  .footerColumnOne {
    align-items: flex-start;
    justify-content: space-between
  }

  .footerColumnFour {
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (max-width: 480px) {
  .section {
    height: auto;
  }

  .boxes {
    display: none;
  }

  .header {
    font-size: 28px;
  }

  .title {
    font-size: 22px;
  }
}

/* .container {
  padding: 0 2rem;
} */

/* .main {
  min-height: 100vh;
  padding: 4rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

/* .footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
} */

/* .footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
} */

/* .title a {
  color: #0070f3;
  text-decoration: none;
} */

/* .title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
} */

/* .title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
} */

/* .title,
.description {
  text-align: center;
} */

/* .description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
} */

/* .code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
} */

/* .grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
} */

/* .card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;
} */

/* .card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
} */

/* .card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
} */

/* .card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
} */

/* .logo {
  height: 1em;
  margin-left: 0.5rem;
} */

@media (max-width: 600px) {
  /* .grid {
    width: 100%;
    flex-direction: column;
  } */
}

@media (prefers-color-scheme: dark) {
  /* .card,
  .footer {
    border-color: #222;
  }
  .code {
    background: #111;
  }
  .logo img {
    filter: invert(1);
  } */
}
